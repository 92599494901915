<template>
  <div>
    <v-card
      flat
      class="datatable-card-background datatable-card-border rounded mb-6"
    >
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="auto"
          >
            <app-branch-select
              v-model="queryParams.branch_id"
              classes="small-input"
              hide-details
            />
          </v-col>

          <v-col>
            <v-btn
              depressed
              small
              class="me-6"
              @click="$_print()"
            >
              <v-icon left>
                mdil-printer
              </v-icon>

              {{ $t('Print') }}
            </v-btn>

            <v-btn
              color="primary"
              depressed
              small
              @click="getData()"
            >
              <v-icon left>
                mdil-magnify
              </v-icon>

              {{ $t('Search') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      flat
      class="datatable-card-background datatable-card-border rounded"
    >
      <v-card-text>
        <v-simple-table
          id="print-area"
          class="print-table"
          dense
        >
          <template #default>
            <thead>
              <tr v-if="apiData.total">
                <th
                  colspan="3"
                  class="text-center text-body-2 font-weight-bold"
                >
                  {{ $t('Total Customers') }}: {{ $_format_number(apiData.total.count) }}
                </th>
                <th
                  colspan="2"
                  class="text-center text-body-2 font-weight-bold"
                >
                  {{ $t('Total Amount') }}: {{ $_format_number(apiData.total.total) }}
                </th>
              </tr>
              <tr>
                <th class="text-center">
                  #
                </th>
                <th>{{ $t('Customer Name') }}</th>
                <th>{{ $t('Phone') }}</th>
                <th>{{ $t('Item Name') }}</th>
                <th class="text-center">
                  {{ $t('Monthly Receipt Amount') }}
                </th>
                <th class="text-center">
                  {{ $t('Receipt Date') }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(data, index) in apiData.data"
                :key="index"
                :class="{
                  'grey': index % 2 == 1,
                  'lighten-3': !$vuetify.theme.dark,
                  'darken-3': $vuetify.theme.dark,
                }"
              >
                <td class="text-center">
                  {{ index + 1 }}
                </td>
                <td class="text-start">
                  {{ data.customer_name }}
                </td>
                <td class="text-start">
                  {{ data.customer_phone1 }}
                </td>
                <td class="text-start">
                  {{ data.item }}
                </td>
                <td class="text-center">
                  $ {{ $_format_number(data.monthly_receipt_amount) }}
                </td>
                <td class="text-center">
                  {{ $_format_date(data.next_receipt_date) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      apiData: {},
      queryParams: {},
    }
  },

  mounted() {
    this.$_section_title({ title: 'Qist', icon: 'mdil-chart-bar' })
  },

  methods: {
    getData() {
      axios.post(`report/qist/`, this.queryParams).then(res => {
        this.apiData = res.data
      })
    }
  },
}
</script>

<style lang="scss" scoped>
</style>